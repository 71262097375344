import axios from "axios";
import { SyntheticEvent, useState } from "react";
import { Constants } from "../Constants";
import { S_Event } from "../model";
import errorHandler from "../util/catchError";

const SettingCreateEvent = (props:{
  setEvents: (e: S_Event[]) => void;
}) => {
  const {setEvents} = props;
  const todaynow = new Date();
  const [eName, setEName] = useState("");
  const [date, setDate] = useState(todaynow.toISOString().split("T")[0]);

  const submitHandler = async(e: SyntheticEvent) => {
    e.preventDefault();
    if (window.confirm("do you want to add "+ eName + "?")){
      try {
        const res = await axios.post(Constants.URL_EVENT, {name:eName, date});
        const res2 = await axios.get(Constants.URL_EVENT_ALL);
        setEvents(res2.data);
        window.alert(res.data.result);
      } catch (error:unknown) {
        errorHandler(error);
      }
    }
  };

  return (
    <>
      <h3>Add Event</h3>
      <form onSubmit={submitHandler}>
        <input
          type="text"
          placeholder="name of event"
          value={eName}
          onChange={(e) => setEName(e.target.value)}
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        ></input>
        <button type="submit" disabled={eName.trim().length<3}>Add</button>
      </form>
    </>
  );
};

export default SettingCreateEvent;