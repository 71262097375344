import { useSelector } from "react-redux";
import { TokenAuth } from "../model";
import { RootState } from "../store/indexStore";
import { AppBar, Button, Container, Toolbar } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import "./header.css"

const getPathVar = (url:string) =>{
  const spliturl = url.split("/");
  if (spliturl.length==0) return ""
  return spliturl.splice(-1)[0];
}

const BoldButton = (props:{txt:string, curloc:string, cont:string})=>{
  const {txt, curloc, cont} = props
  if (getPathVar(curloc) === txt)
    return (<Button variant="text"><b>{cont}</b></Button>)
  return (<Button variant="text">{cont}</Button>)
}


const HeaderBar: React.FC<{ htitle: string }> = (props) => {
  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );
  const location = useLocation();

  return (
    <AppBar position="sticky" sx={{ bgcolor: "white", flexGrow: 1 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <h1 className="headerH1">{props.htitle}</h1>
          {loggedStatus.isLoggedIn && (
            <Link to="/admin/setting">
              <BoldButton txt="setting" curloc={location.pathname} cont="Setting"/>
            </Link>
          )}
          {loggedStatus.isLoggedIn && loggedStatus.role === "super_admin" && (
            <Link to="/admin/dashboard">
              <BoldButton txt="dashboard" curloc={location.pathname} cont="DashBoard"/>
            </Link>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to="/admin/paradestate">
              <BoldButton txt="paradestate" curloc={location.pathname} cont="Parade State"/>
            </Link>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to="/admin/leavelist">
              <BoldButton txt="leavelist" curloc={location.pathname} cont="LeaveList"/>
            </Link>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to="/admin/feedback">
              <BoldButton txt="feedback" curloc={location.pathname} cont="FeedBack"/>
            </Link>
          )}
          <span className="spacerAdminHeader"> </span>

          {loggedStatus.isLoggedIn && (
            <span className="admininfo">
              {loggedStatus.username}
              <br />
              {loggedStatus.role}
              <br />
              {loggedStatus.location}
            </span>
          )}
          {loggedStatus.isLoggedIn && (
            <Link to={"/logout"}>
              <Button variant="contained">Logout</Button>
            </Link>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderBar;
