const ParadeStateTable = () => {
  return (
    <table className="copytable">
      <thead>
        <tr>
          <th>Status</th>
          <th>Detail</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>PRESENT</td>
          <td>PERSONNEL PHYSICALLY PRESENT IN DENTAL CENTRES</td>
        </tr>
        <tr>
          <td>LEAVE(AL/OL)</td>
          <td>PLEASE INDICATE AL (ANNUAL LEAVE) OR OL(OVERSEAS LEAVE)</td>
        </tr>
        <tr>
          <td>MC/HL</td>
          <td>
            PLEASE INDICATE MC (MEDICAL LEAVE) OR HL(HOSPITALISATION LEAVE) AND
            INDICATE PERIOD OF MC OR HL IN REMARK COLUMN
          </td>
        </tr>
        <tr>
          <td>OFF</td>
          <td>PLEASE INDICATE NUMBER OF DAYS OFF IN REMARKS COLUMN</td>
        </tr>
        <tr>
          <td>MA</td>
          <td>
            PLEASE INDICATE LOCATION OF MEDICAL APPT IN REMARKS COLUMN (I.E.
            NUH/NDC/MMI ETC.)
          </td>
        </tr>
        <tr>
          <td>OC</td>
          <td>
            PLEASE INDICATE WHAT COURSE AND COURSE PERIOD ON REMARKS COLUMNS
          </td>
        </tr>
        <tr>
          <td>OUTSTATION</td>
          <td>
            WHEN PERSONNEL ARE VISITING OR POSTED TEMPORARILY TO ANOTHER DENTAL
            CENTRE. <br />
            PLEASE INDICATE ATTACHMENT PERIOD ON REMARKS COLUMN
          </td>
        </tr>
        <tr>
          <td>OTHERS</td>
          <td>
            IF PERSONNEL IS NOT PRESENT IN DENTAL CENTRE, PLEASE INDICATE
            REASONS IN REMARKS. <br />
            (I.E. ATTENDING SEMINAR/CONFERENCE OR OTHER REASONS)
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ParadeStateTable;
