import { SyntheticEvent, useState } from "react";
import { AdmRole, AdminInfo, MC, Result } from "../model";
import axios from "axios";
import { Constants } from "../Constants";
import errorHandler from "../util/catchError";

const SettingCreateAdmin = (props: {
  locations : MC[],
  setAllAdminInfo : (info:AdminInfo[]) => void
}) => {
  const [admUsername, setAdmUsername] = useState("");
  const [pw, setPw] = useState("");
  const [admloc, setAdmloc] = useState<MC>({ id: 1, name: "dental_sdc" });
  const [admRole, setAdmRole] = useState<AdmRole>({ role: "admin", id: 2 });
  const [admRoles, setAdmRoles] = useState<AdmRole[]>([
    { role: "admin", id: 2 },
  ]);
  const {locations , setAllAdminInfo} = props;
  const createAdmHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    try {
      const response = await axios.post<Result>(Constants.URL_ADMIN, {
        username: admUsername,
        pw,
        role: admRole.id,
        location: admloc.id,
      });
      alert(response.data.result);
      axios.get<AdminInfo[]>(Constants.URL_ADMINS).then((res) => {
        setAllAdminInfo(res.data);
      });
    } catch (error:unknown) {
      errorHandler(error);
    }
  };

  return (
    <div>
      <h3>Create New Admin</h3>
      <form onSubmit={createAdmHandler}>
        <label htmlFor="admusername" style={{ display: "block" }}>
          Admin Username
        </label>
        <input
          id="admusername"
          type="text"
          value={admUsername}
          onChange={(e) => setAdmUsername(e.target.value)}
          required
        />
        <label htmlFor="pw" style={{ display: "block" }}>
          Password
        </label>
        <input
          id="pw"
          type="password"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          required
        />
        <br />
        <label htmlFor="role" style={{ display: "block" }}>
          Role
        </label>
        <select
          name="role"
          id="role"
          value={admRole.id}
          onChange={(e) =>
            setAdmRole({
              id: +e.target.value,
              role: admRoles.filter((x) => x.id === +e.target.value)[0].role,
            })
          }
        >
          {admRoles.map((r) => {
            return (
              <option value={r.id} key={r.id}>
                {r.role}
              </option>
            );
          })}
        </select>
        <label htmlFor="location" style={{ display: "block" }}>
          location
        </label>
        <select
          name="location"
          id="location"
          value={admloc.id}
          onChange={(e) =>
            setAdmloc({
              id: +e.target.value,
              name: locations.filter((x) => x.id === +e.target.value)[0].name,
            })
          }
        >
          {locations.map((l) => {
            return (
              <option value={l.id} key={l.id}>
                {l.name}
              </option>
            );
          })}
        </select>
        <br />
        <button type="submit">create</button>
      </form>
    </div>
  );
};

export default SettingCreateAdmin;
