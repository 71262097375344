import axios from "axios";
import { SyntheticEvent, useState } from "react";
import { Constants } from "../Constants";
import errorHandler from "../util/catchError";

const Feedback = () => {
  const [fb, setFb] = useState("");

  const onSubmit = async(e:SyntheticEvent)=>{
    e.preventDefault();
    try {
      await axios.post(Constants.URL_FEEDBACK,{fb});
      window.alert("posted")
      setFb("")
    } catch (error:unknown) {
      errorHandler(error);
    }
  }

  return (
    <div>
      <label htmlFor="feedback">Pls write feedback/bug below</label>
      <br />
      <textarea
        id="feedback"
        name="feedback"
        placeholder="feedback/bug?"
        value={fb}
        onChange={(e) => setFb(e.target.value)}
        rows={4}
        cols={50}
      ></textarea>
      <br />
      <button disabled={fb.trim().length<=3} onClick={(e)=>onSubmit(e)}>Submit</button>
    </div>
  );
};

export default Feedback;
