import React, { SyntheticEvent, useState } from "react";
import Select, { MultiValue, SingleValue } from "react-select";
import { selectOpt } from "../model";
import axios from "axios";
import { Constants } from "../Constants";
import errorHandler from "../util/catchError";

const SettingCreatePersonnel = (props: {
  // setPers: (ppl: Ppl[]) => void;
  locationOpt: selectOpt[];
  apptOpt: selectOpt[];
  rankOpt: selectOpt[];
  setRefresh:(value: React.SetStateAction<boolean>) => void
}) => {
  const [pName, setPName] = useState("");
  const [pRank, setPRank] = useState(1);
  const [pAppt, setPAppt] = useState(1);
  const [pLoc, setPLoc] = useState<number[]>([]);

  const rankSelectHandler = (e: SingleValue<selectOpt>) => {
    if (e?.value !== undefined) setPRank(+e?.value);
  };

  const apptSelectHandler = (e: SingleValue<selectOpt>) => {
    if (e?.value !== undefined) setPAppt(+e?.value);
  };

  const locationSelectHandler = (e: MultiValue<selectOpt>) => {
    const tmp: number[] = [];
    e.forEach((el) => tmp.push(+el.value));
    setPLoc(tmp);
  };

  const { locationOpt, rankOpt, apptOpt, setRefresh } = props;
  const createPersonHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    try {
      const res = await axios.post(Constants.URL_PERSON, {
        name: pName,
        rank: pRank,
        appointment: pAppt,
        location: pLoc,
      });
      setRefresh(prev => !prev);
      alert(res.data.result);
    } catch (error:unknown) {
      errorHandler(error);
    }
  };

  return (
    <>
      <h3>Create Personnel</h3>
      <form onSubmit={createPersonHandler}>
        <div className="selectContainer" style={{width:"150px"}}>
          <Select options={rankOpt} onChange={rankSelectHandler} />
        </div>
        <label htmlFor="pName" style={{ display: "block" }}>
          Name
        </label>
        <input
          type="text"
          id="pName"
          name="pName"
          onChange={(e) => setPName(e.target.value)}
          value={pName}
        />
        <div className="selectContainer" style={{width:"150px"}}>
          <Select options={apptOpt} onChange={apptSelectHandler} />
          <Select
            options={locationOpt}
            onChange={locationSelectHandler}
            isMulti
          />
        </div>

        <br />
        <button type="submit">create</button>
      </form>
    </>
  );
};

export default SettingCreatePersonnel;
