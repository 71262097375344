import axios from "axios";
import { SyntheticEvent, useState } from "react";
import { Constants } from "../Constants";
import { holidaySlice } from "../store/holiday";
import store from "../store/indexStore";
import errorHandler from "../util/catchError";

const SettingCreateHoliday = () => {
  const todaynow = new Date();
  const [eName, setEName] = useState("");
  const [date, setDate] = useState(todaynow.toISOString().split("T")[0]);

  const submitHandler = async(e: SyntheticEvent) => {
    e.preventDefault();
    if (window.confirm("do you want to add "+ eName + "?")){
      try {
        const res = await axios.post(Constants.URL_HOLIDAY, {name:eName, date});
        const res2 = await axios.get(Constants.URL_HOLIDAY_PUB);
        store.dispatch(holidaySlice.actions.setHoliday(res2.data));
        window.alert(res.data.result);
      } catch (error:unknown) {
        errorHandler(error);
      }
    }
  };

  return (
    <>
      <h3>Add Public Holiday</h3>
      <form onSubmit={submitHandler}>
        <input
          type="text"
          placeholder="name of holiday"
          value={eName}
          onChange={(e) => setEName(e.target.value)}
        />
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        ></input>
        <button type="submit" disabled={eName.trim().length<3}>Add</button>
      </form>
    </>
  );
};

export default SettingCreateHoliday;