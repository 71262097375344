import axios from "axios";
import { SyntheticEvent, useState } from "react";
import { Constants } from "../Constants";
import { TokenAuth } from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";
import errorHandler from "../util/catchError";

const SettingChangePw = () => {
  const adminDefault: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );

  const [changePw, setChangePw] = useState<{
    oldPw: string;
    newPw: string;
    newPw1: string;
  }>({ oldPw: "", newPw: "", newPw1: "" });

  const changePWHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { oldPw, newPw, newPw1 } = changePw;
    try {
      if (
        !(oldPw.length === 0 || newPw.length === 0) &&
        newPw === newPw1 &&
        newPw.length >= 6
      ) {
        await axios.put(Constants.URL_ADMIN_PW, {
          ...changePw,
          id: adminDefault.id,
          username: adminDefault.username,
        });
        window.alert("password is changed");
      } else {
        window.alert(
          "password cannot be empty, new password verification failed or new password length must be 6 or more."
        );
      }
      setChangePw({ oldPw: "", newPw: "", newPw1: "" });
    } catch (error: unknown) {
      errorHandler(error);
    }

  };

  return (
    <div>
      <h3>Change Password</h3>
      <form onSubmit={changePWHandler}>
        <label htmlFor="oldpw" style={{ display: "block" }}>
          old password
        </label>
        <input
          type="password"
          name="oldpw"
          id="oldpw"
          value={changePw.oldPw}
          onChange={(e) =>
            setChangePw((prev) => {
              return { ...prev, oldPw: e.target.value };
            })
          }
        />
        <label htmlFor="newpw" style={{ display: "block" }}>
          new password
        </label>
        <input
          type="password"
          name="newpw"
          id="newpw"
          value={changePw.newPw}
          onChange={(e) =>
            setChangePw((prev) => {
              return { ...prev, newPw: e.target.value };
            })
          }
        />
        <label htmlFor="newpw1" style={{ display: "block" }}>
          confirm new password
        </label>
        <input
          type="password"
          name="newpw1"
          id="newpw1"
          value={changePw.newPw1}
          onChange={(e) =>
            setChangePw((prev) => {
              return { ...prev, newPw1: e.target.value };
            })
          }
        />
        <br />
        <button type="submit">Change Pw</button>
      </form>
    </div>
  );
};

export default SettingChangePw;
