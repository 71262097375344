import { SyntheticEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Attendance,
  AttendanceGetbyLocDate,
  Person,
  PplinMCResult,
  S_Event,
  Status,
  TokenAuth,
  selectOpt,
} from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";
import axios from "axios";
import { Constants } from "../Constants";
import { Calendar, DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Select, { SingleValue } from "react-select";
import "./Leave.css";
import errorHandler from "../util/catchError";

const getDatesInRange = (startDate: Date, endDate: Date) => {
  const date = new Date(startDate.getTime());
  const dates = [];
  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

// create working day map with key=YYMMDD and value=index of arr
const workingDaymap = (year: number, holiday: S_Event[]) => {
  const firstday = new Date(year + "-01-01");
  const holiSet: Set<number> = new Set();
  for (let holi of holiday) {
    let a = new Date(holi.date);
    holiSet.add(a.getTime());
  }
  const map: Map<number, number> = new Map();
  let count = 0;
  for (let i = 0; i < 390; i++) {
    if (
      firstday.getDay() == 0 ||
      firstday.getDay() == 6 ||
      holiSet.has(firstday.getTime())
    ) {
      firstday.setDate(firstday.getDate() + 1);
      continue;
    }
    map.set(
      (firstday.getFullYear() % 100) * 10000 +
        (firstday.getMonth() + 1) * 100 +
        firstday.getDate(),
      count
    );
    firstday.setDate(firstday.getDate() + 1);
    count++;
  }
  return map;
};

// date format in YYYY-MM-DD "2024-08-14" = 240814
const datestrtonum = (datestr: string) => {
  if (datestr.length != 10) return 0;
  return parseInt(
    datestr.charAt(2) +
      datestr.charAt(3) +
      datestr.charAt(5) +
      datestr.charAt(6) +
      datestr.charAt(8) +
      datestr.charAt(9),
    10
  );
};

// use sliding window to check for 14 consecutive day of leave
const consecLeave = (
  attendances: Attendance[],
  holiday: S_Event[],
  year: number,
  lmt: number
) => {
  const map = workingDaymap(year, holiday);
  const arr: boolean[] = new Array(map.size);
  arr.fill(false);
  const leaves = attendances.filter(
    (x) =>
      (x.amstatus == Constants.STATUS_LEAVEAL ||
        x.amstatus == Constants.STATUS_LEAVEOL) &&
      (x.pmstatus == Constants.STATUS_LEAVEAL ||
        x.pmstatus == Constants.STATUS_LEAVEOL)
  );
  for (let l of leaves) {
    if (!map.has(datestrtonum(l.date))) continue;
    arr[map.get(datestrtonum(l.date)) ?? 0] = true;
  }
  let lp = 0;
  let rp = 0;
  while (lp < map.size) {
    if (!arr[lp]) {
      lp++;
      continue;
    }
    rp = 0;
    while (arr[lp + rp]) {
      rp++;
      if (rp > lmt) return false;
    }
    lp += rp;
  }
  return true;
};

const Leave = () => {
  const todaynow = new DateObject();
  const tmw = todaynow.add(1, "days");
  const { id } = useParams();
  const [dates, setDates] = useState<DateObject[]>();
  const [attendances, setAttendances] = useState<Attendance[]>([]);
  const [pers, setPers] = useState<Person>({
    id: -1,
    name: "",
    rankName: "",
    apptName: "",
  });
  const [submitBy, setSubmitBy] = useState("");
  const [events, setEvents] = useState<S_Event[]>([]);
  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );
  const states: Status[] = useSelector(
    (state: RootState) => state.status
  ).filter((x) => {
    return (
      x.state === "Leave(AL)" ||
      x.state === "Leave(OL)" ||
      x.state === "HL" ||
      x.state === "MC" ||
      x.state === "MA" ||
      x.state === "Reservist"
    );
  });
  const holidays = useSelector((state: RootState) => state.holiday);
  const statemap = new Map<number, string>();
  for (let s of states) statemap.set(+s.id, s.state);
  statemap.set(-1, "undefined");

  const statuss: selectOpt[] = [];
  for (let s of states) statuss.push({ value: s.id, label: s.state });
  const [amstatus, setAmstatus] = useState<SingleValue<selectOpt>>(statuss[0]);
  const [pmstatus, setPmstatus] = useState<SingleValue<selectOpt>>(statuss[0]);
  const [rmk, setRmk] = useState("");
  const [found, setFound] = useState(false);
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    let pers: Person;
    let attendances: Attendance[] = [];
    axios
      .get<PplinMCResult>(
        Constants.URL_PERSON_IN_MC + "?mc=" + loggedStatus.location
      )
      .then((res) => {
        let tmp = res.data.personnel.filter((p) => p.id === +(id ?? 0));
        if (tmp.length >= 1) {
          pers = {
            id: tmp[0].id,
            name: tmp[0].name,
            rankName: tmp[0].rank.rankName,
            apptName: tmp[0].appointment.apptName,
          };
          setPers(pers);
          setFound(true);
        }
      })
      .then(() => {
        return axios.get<AttendanceGetbyLocDate[]>(
          Constants.URL_ATTENDANCE + pers.id,
          {
            params: {
              date: tmw.format("YYYY-MM-DD"),
              locid: loggedStatus.locid,
            },
          }
        );
      })
      .then((res) => {
        for (let att of res.data) {
          attendances.push({
            attid: att.id,
            date: att.date,
            personnel: att.personnel.id,
            amstatus: att.amstatus.id,
            pmstatus: att.pmstatus.id,
            remark: att.remark,
            locid: loggedStatus.locid ?? -1,
            submittedby: att.submittedby,
          });
        }
        console.log(attendances);
        setAttendances(attendances);
      })
      .catch((err) => console.log(err));
    axios
      .get(Constants.URL_EVENT_ALL)
      .then((res) => {
        let evts: S_Event[] = res.data;
        evts.reverse();
        setEvents(evts);
      })
      .catch((err) => console.log(err));
  }, [id, refresh]);

  const dataFunc = (date: DateObject) => {
    let isWeekend = [0, 6].includes(date.weekDay.index);
    if (isWeekend)
      return {
        disabled: true,
        style: { color: "#ccc" },
        onClick: () => alert("weekends are disabled."),
      };
    let leaveatt: string[] = [];
    let sickatt: string[] = [];
    attendances.forEach((a) => {
      if (
        (a.amstatus === Constants.STATUS_MC ||
          a.amstatus === Constants.STATUS_HL ||
          a.amstatus === Constants.STATUS_MA) &&
        (a.pmstatus === Constants.STATUS_MC ||
          a.pmstatus === Constants.STATUS_HL ||
          a.amstatus === Constants.STATUS_MA)
      )
        sickatt.push(a.date);
      else leaveatt.push(a.date);
    });
    let isLeave = leaveatt.includes(date.format("YYYY-MM-DD"));
    if (isLeave)
      return {
        disabled: true,
        style: {
          color: "#000",
          borderRadius: "3px",
          backgroundColor: "#90EE90",
        },
        onClick: () => alert("leave cannot be applied twice."),
      };
    let isSickLeave = sickatt.includes(date.format("YYYY-MM-DD"));
    if (isSickLeave)
      return {
        disabled: true,
        style: {
          color: "#000",
          borderRadius: "3px",
          backgroundColor: "#FF0000",
        },
        onClick: () => alert("leave cannot be applied twice."),
      };
    let holi = holidays.filter((h) => h.date === date.format("YYYY-MM-DD"));
    if (holi.length > 0)
      return {
        disabled: true,
        style: { color: "#ccc" },
        title: holi[0].name,
      };
    let evt = events.filter((e) => e.date === date.format("YYYY-MM-DD"));
    if (evt.length > 0)
      return {
        style: {
          borderRadius: "3px",
          color: "#FF0000",
          backgroundColor: "#CCC",
        },
        title: evt[0].name,
      };
  };

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    // attendances.length = 0;
    const tmp: Attendance[] = [];
    if (dates && amstatus && pmstatus && loggedStatus) {
      for (let date of dates) {
        tmp.push({
          date: date.format("YYYY-MM-DD"),
          personnel: pers.id,
          amstatus: +amstatus.value,
          pmstatus: +pmstatus.value,
          locid: loggedStatus.locid as number,
          remark: rmk,
          submittedby: submitBy,
        });
      }
      const leavesTaken = attendances.filter(
        (x) =>
          (x.amstatus == Constants.STATUS_LEAVEAL ||
            x.amstatus == Constants.STATUS_LEAVEOL) &&
          (x.pmstatus == Constants.STATUS_LEAVEAL ||
            x.pmstatus == Constants.STATUS_LEAVEOL)
      );
      if (
        pers.apptName == Constants.DENTALOFFICER &&
        !consecLeave(
          [...tmp, ...leavesTaken],
          holidays,
          todaynow.year,
          Constants.LEAVE_LMT
        )
      ) {
        window.alert(
          `more than consecutive ${Constants.LEAVE_LMT} leaves detected!`
        );
        return;
      }
    } else window.alert("error");
    console.log(tmp);
    if (window.confirm("do you want to submit leave forecast?")) {
      try {
        // await axios.post(Constants.URL_ATTENDANCE, tmp);
        await axios.post(Constants.URL_LEAVE, tmp);
        setRefresh((prev) => !prev);
        setAttendances(tmp);
        setDates([]);
        window.alert("submitted");
      } catch (error: unknown) {
        errorHandler(error);
      }
    }
  };

  const delHandler = async (id: number) => {
    try {
      await axios.delete(Constants.URL_ATTENDANCE + id);
      setRefresh((prev) => !prev);
      window.alert("deleted " + id);
    } catch (error: unknown) {
      errorHandler(error);
    }
  };

  return (
    <div>
      <h3>Leave forecast for {pers.rankName + " " + pers.name}</h3>
      {found ? (
        <>
          <form onSubmit={submitHandler}>
            <table>
              <thead>
                <tr>
                  <th>Date Range</th>
                  <th>am Status</th>
                  <th>pm Status</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Calendar
                      minDate={tmw}
                      plugins={[<DatePanel />]}
                      value={dates}
                      onChange={(e) => setDates(e)}
                      weekStartDayIndex={1}
                      mapDays={({ date }) => dataFunc(date)}
                      format="MMM DD YYYY"
                      multiple
                      sort
                    />
                  </td>
                  <td>
                    <Select
                      options={statuss}
                      value={amstatus}
                      onChange={(e) => setAmstatus(e)}
                    />
                  </td>
                  <td>
                    <Select
                      options={statuss}
                      value={pmstatus}
                      onChange={(e) => setPmstatus(e)}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={rmk}
                      onChange={(e) => setRmk(e.target.value)}
                      placeholder="more than 3 letters"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <input
              type="text"
              placeholder="submitted by??"
              value={submitBy}
              onChange={(e) => setSubmitBy(e.target.value)}
            />
            <button
              type="submit"
              disabled={submitBy.trim().length < 3 || rmk.trim().length < 3}
              onClick={(e) => submitHandler(e)}
            >
              Submit
            </button>
          </form>
          <h3>Leave In System</h3>
          <table className="leaveTable">
            <thead>
              <tr>
                <th>attid</th>
                <th>date</th>
                <th>Person id</th>
                <th>am Status</th>
                <th>pm Status</th>
                <th>remark</th>
                <th>submitted by</th>
                <th>delete?</th>
              </tr>
            </thead>
            <tbody>
              {attendances.map((att) => {
                return (
                  <tr key={att.attid}>
                    <td>{att.attid}</td>
                    <td>{att.date}</td>
                    <td>{att.personnel}</td>
                    <td>{statemap.get(att.amstatus ?? -1)}</td>
                    <td>{statemap.get(att.pmstatus ?? -1)}</td>
                    <td>{att.remark}</td>
                    <td>{att.submittedby}</td>
                    <td>
                      <button
                        type="button"
                        onClick={() => delHandler(att.attid ?? -1)}
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      ) : (
        "not found"
      )}
    </div>
  );
};

export default Leave;
