import { SyntheticEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  GetAttByPidSidYr,
  MC,
  Status,
  TokenAuth,
  selectOpt,
} from "../model";
import axios from "axios";
import { Constants } from "../Constants";
import "./AttEdit.css";
import Select from "react-select";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";
import errorHandler from "../util/catchError";

const AttEdit = () => {
  const attInitMod: GetAttByPidSidYr = {
    id: -1,
    date: "",
    amstatus: { id: -1, state: "" },
    pmstatus: { id: -1, state: "" },
    personnel: {
      id: -1,
      name: "",
      rank: { id: -1, rankName: "" },
      appointment: { id: -1, apptName: "" },
    },
    location: { id: -1, name: "" },
    submittedby: "",
  };

  const { id, aid } = useParams();
  const [attModel, setAttModel] = useState<GetAttByPidSidYr>(attInitMod);
  const [amState, setAmState] = useState(Constants.STATUS_PRESENT);
  const [pmState, setPmState] = useState(Constants.STATUS_PRESENT);
  const [loc, setLoc] = useState(1);
  const [rmk, setRmk] = useState("");
  const [submitBy, setSubmitBy] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [isRmkEmpty, setIsRmkEmpty] = useState(true);

  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );

  // load locations and status from redux
  const states: Status[] = useSelector((state: RootState) => state.status);
  const statuss: selectOpt[] = [];
  for (let s of states) statuss.push({ value: +s.id, label: s.state });
  const locations: MC[] = useSelector((state: RootState) => state.mc).loc;
  const locs: selectOpt[] = [];
  for (let loc of locations) locs.push({ value: +loc.id, label: loc.name });

  useEffect(() => {
    const locs: selectOpt[] = [];
    const statuss: selectOpt[] = [];
    axios
      .get<GetAttByPidSidYr[]>(Constants.URL_ATTENDANCE + aid)
      .then((res) => {
        setAttModel(res.data[0]);
        setLoc(res.data[0].location.id);
        setAmState(res.data[0].amstatus.id);
        setPmState(res.data[0].pmstatus.id);
        if (res.data[0].remark) {
          setRmk(res.data[0].remark);
          setIsRmkEmpty(false);
        }
      })
      .catch((err) => console.log(err));
  }, [id, aid, refresh]);

  const editHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    if (window.confirm("Are you sure that you want to edit?")) {
      try {
        // if (rmk.trim().length === 0) {
        //   const result = await axios.put(Constants.URL_ATTENDANCE, {
        //     id: attModel.id,
        //     amstatusid: amstate,
        //     pmstatusid: pmstate,
        //     personnelid: attModel.personnel.id,
        //     locid: loc,
        //     submittedby,
        //   });
        //   setRefresh((prev) => !prev);
        //   window.alert(result.data.result);
        // } else {
        const result = await axios.put(Constants.URL_ATTENDANCE, {
          id: attModel.id,
          amstatusid: amState,
          pmstatusid: pmState,
          personnelid: attModel.personnel.id,
          locid: loc,
          remark: !isRmkEmpty && rmk == "" ? " " : rmk,
          submittedby: submitBy,
        });
        setRefresh((prev) => !prev);
        window.alert(result.data.result);
        // }
      } catch (error:unknown) {
        errorHandler(error);
      }
    }
  };

  return (
    <div>
      <form onSubmit={editHandler}>
        {loggedStatus.role === Constants.ADMIN_SU ? (
          <h3>
            <Link to={"/admin/person/" + id}>{attModel.personnel.name}</Link>
          </h3>
        ) : (
          <h3>{attModel.personnel.name}</h3>
        )}
        <table className="editAttTable">
          <thead>
            <tr>
              <th>id</th>
              <th>date</th>
              <th>am state</th>
              <th>pm state</th>
              <th>location</th>
              <th>remark</th>
              <th>Edit?</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{attModel.id}</td>
              <td>{attModel.date}</td>
              <td>
                <Select
                  options={statuss}
                  value={statuss.filter((s) => s.value === amState)[0]}
                  onChange={(e) => setAmState(e?.value as number)}
                />
              </td>
              <td>
                <Select
                  options={statuss}
                  value={statuss.filter((s) => s.value === pmState)[0]}
                  onChange={(e) => setPmState(e?.value as number)}
                />
              </td>
              <td>
                <Select
                  options={locs}
                  value={locs.filter((l) => l.value === loc)[0]}
                  onChange={(e) => setLoc(e?.value as number)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={rmk}
                  onChange={(e) => setRmk(e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={submitBy}
                  placeholder="editted by ???"
                  onChange={(e) => setSubmitBy(e.target.value)}
                />
                <button type="submit" disabled={submitBy.trim().length < 2}>
                  Edit?
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default AttEdit;
