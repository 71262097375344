import axios from "axios";
import { AdminInfo, Result, TokenAuth } from "../model";
import { Constants } from "../Constants";
import errorHandler from "../util/catchError";

const SettingAdminList = (props: {
  adminDefault: TokenAuth;
  setAllAdminInfo: (info: AdminInfo[]) => void;
  allAdminInfo: AdminInfo[];
}) => {
  const { adminDefault, setAllAdminInfo, allAdminInfo } = props;
  const deleteHandler = async (username: string, id: number) => {
    if (window.confirm("Do you want to delete?") !== true) return;
    try {
      const response = await axios.delete<Result>(Constants.URL_ADMIN, {
        data: { username, id },
      });
      alert(response.data.result);
      axios.get<AdminInfo[]>(Constants.URL_ADMINS).then((res) => {
        setAllAdminInfo(res.data);
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <>
      <h3>List of Admin</h3>
      <table className="alluser">
        <thead>
          <tr>
            <th>id</th>
            <th>username</th>
            <th>role</th>
            <th>location</th>
            <th>remark</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {allAdminInfo.map((info) => {
            return (
              <tr key={info.id}>
                <td>{info.id}</td>
                <td>{info.username}</td>
                <td>{info.auth.role}</td>
                <td>{info.location.name}</td>
                <td>{info.remark}</td>
                <td>
                  <button
                    type="button"
                    disabled={info.id === adminDefault.id}
                    onClick={() => deleteHandler(info.username, info.id)}
                  >
                    Delete?
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default SettingAdminList;
