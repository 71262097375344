import React from "react";
import "./RootPg.css";
import { Outlet } from "react-router-dom";
import Header from "../component/header";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";

const RootPg = () => {
  const admin = useSelector((state: RootState) => state.admin )
  
  return (
    <div className="RootContainer">
      <Header htitle={admin?.location ?? "MedicalCentre"} />
      <Outlet />
    </div>
  );
};

export default RootPg;
