import axios from "axios";
import { CustomError } from "../model";

const errorHandler = (error:unknown)=>{
  if (axios.isAxiosError<CustomError>(error)){
    window.alert(error.response?.data.errors[0].message)
  }
  else if (error instanceof Error) window.alert(error.message)
  else window.alert(error);
}

export default errorHandler;