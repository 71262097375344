import React, { useEffect, useState } from "react";
import "./DashBoard.css";
import {
  Attendance,
  GetAttByPidSidYr,
  MC,
  PplGetResult,
  PplinMCResult,
  TokenAuth,
} from "../model";
import axios from "axios";
import { Constants } from "../Constants";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";
import DashBoardCenterAttList from "./DashBoardCenterAttList";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DashBoardWeekly from "./DashBoardWeekly";
import DashBoardMonthly from "./DashBoardMonthly";
import DboardSelectLoc from "./DboardSelectLoc";
import StrengthTable from "./StrengthTable";

const totalStrengthPast = (attD: GetAttByPidSidYr[]) => {
  const set: Set<number> = new Set();
  for (let a of attD) set.add(a.personnel.id);
  return set.size;
};

// session <=0 for am and session >= 1 for pm
const totalSessionStrengthPast = (
  attD: GetAttByPidSidYr[],
  session: number,
  dr: boolean,
  totalStrengthPast: (sess: GetAttByPidSidYr[]) => number
) => {
  const sess =
    session <= 0
      ? attD.filter((a) => a.amstatus.id === Constants.STATUS_PRESENT)
      : attD.filter((a) => a.pmstatus.id === Constants.STATUS_PRESENT);

  return dr
    ? totalStrengthPast(
        sess.filter(
          (a) => a.personnel.appointment.id === Constants.DENTALOFFICERID
        )
      )
    : totalStrengthPast(sess);
};

const totalStrengthPresent = (pplinmc:Map<number, PplGetResult[]>)=>{
  const set = new Set<number>();
  const pArray:PplGetResult[] = [];
  for (let locid of pplinmc.keys()){
    for (let ppls of pplinmc.get(locid)??[]){
      if (ppls?.id && set.has(ppls?.id)) continue;
      if (ppls?.id){
        set.add(ppls?.id);
        pArray.push(ppls)
      }
    }
  }
  return pArray;
}

const converttotalPast = (attD: GetAttByPidSidYr[]): Attendance[] => {
  const att: Attendance[] = [];
  for (let a of attD) {
    att.push({
      date: a.date,
      personnel: a.personnel.id,
      amstatus: a.amstatus.id,
      pmstatus: a.pmstatus.id,
      remark: a.remark,
      locid: a.location.id,
      attid: a.id,
      submittedby: a.submittedby,
    });
  }
  return att;
};

const converttotalProjected = (
  ppls: PplGetResult[],
  attD: GetAttByPidSidYr[],
  locid: number
): Attendance[] => {
  const att: Attendance[] = converttotalPast(attD);
  const taken = new Set<number>();
  for (let a of att) taken.add(a.personnel);
  for (let p of ppls) {
    if (taken.has(p.id)) continue;
    att.push({
      date: "",
      personnel: p.id,
      amstatus: Constants.STATUS_PRESENT,
      pmstatus: Constants.STATUS_PRESENT,
      remark: "",
      locid: locid,
      attid: -1,
      submittedby: "",
    });
  }
  return att;
};


const DashBoard = () => {
  const d = new Date();
  // const [pers, setPers] = useState<Ppl[]>([]);
  const [attD, setAttD] = useState<GetAttByPidSidYr[]>([]);
  const [day, setDay] = useState(d.toISOString().split("T")[0]);
  const dateQ = new Date(day);
  const datetoday = new Date(d.toDateString());
  const [pplinmc, setPplinmc] = useState<Map<number, PplGetResult[]>>(
    new Map()
  );
  const [tabIndex, setTabIndex] = useState(0);
  // d.toISOString().split("T")[0]

  // loading MC, Appt and Ranks from redux
  const locations: MC[] = useSelector((state: RootState) => state.mc).loc;
  const admininfo: TokenAuth = useSelector((state: RootState) => state.admin);
  const defLoc: MC = {
    name: admininfo.location ?? "",
    id: admininfo.locid ?? 0,
  };
  const [selLoc, setSelLoc] = useState<MC[]>([defLoc]);
  const dAttD = attD.filter(x=>x.personnel.appointment.id===Constants.DENTALOFFICERID);

  useEffect(() => {
    Promise.all(
      locations.map((loc) =>
        axios.get<PplinMCResult>(Constants.URL_PERSON_IN_MC, {
          params: { mc: loc.name },
        })
      )
    )
      .then((res) => {
        let pplinmc = new Map<number, PplGetResult[]>();
        for (let r of res) pplinmc.set(r.data.id, r.data.personnel);
        setPplinmc(pplinmc);
      })
      .catch((err) => console.log(err));
    axios
      .get<GetAttByPidSidYr[]>(Constants.URL_ATTENDANCE, {
        params: { date: day },
      })
      .then((res) => setAttD(res.data))
      .catch((err) => console.log(err));
  }, [day]);

  return (
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
      <TabList>
        <Tab>Daily</Tab>
        <Tab>Weekly</Tab>
        <Tab>Monthly</Tab>
      </TabList>
      <TabPanel>
        <div className="dashBoardContainer">
          <input
            aria-label="Date"
            type="date"
            value={day}
            onChange={(e) => setDay(e.target.value)}
          />
          <DboardSelectLoc
            locations={locations}
            selLoc={selLoc}
            setSelLoc={setSelLoc}
          />
          <h3>Person By centre</h3>
          {selLoc.map((loc) => {
            return (
              <DashBoardCenterAttList
                key={loc.id}
                d={new Date(day)}
                loc={loc}
                attD={attD}
                pplinmc={pplinmc}
              />
            );
          })}
          {/* strength table for all */}
          { dateQ < datetoday && <div className="strengthtable" style={{marginTop:"50px"}}>
            <StrengthTable attendances={converttotalPast(attD)} subtitle="Full Strength"/>
            <StrengthTable 
              attendances={converttotalPast(dAttD)} 
              subtitle="Full Dentist Strength"
            />
            </div> }
          { dateQ >= datetoday && <div className="strengthtable" style={{marginTop:"50px"}}>
            <StrengthTable attendances={converttotalProjected(totalStrengthPresent(pplinmc),attD,0)} subtitle="Full Strength"/>
            <StrengthTable 
              attendances={converttotalProjected(totalStrengthPresent(pplinmc).filter(x=>x.appointment.id===Constants.DENTALOFFICERID),dAttD,0)} 
              subtitle="Full Dentist Strength"
            />
            </div> }
        </div>
      </TabPanel>
      <TabPanel>
        <DashBoardWeekly
          pplinmc={pplinmc}
          daysfunc={setDay}
          tabfunc={setTabIndex}
        />
      </TabPanel>
      <TabPanel>
        <DashBoardMonthly
          pplinmc={pplinmc}
          daysfunc={setDay}
          tabfunc={setTabIndex}
        />
      </TabPanel>
    </Tabs>
  );
};

export default DashBoard;
