import { Link } from "react-router-dom";
import { Ppl } from "../model";
import { useEffect, useState } from "react";
import axios from "axios";
import { Constants } from "../Constants";
import { Pagination, Stack } from "@mui/material";

const joinArray = (a: any[]) => {
  const array = [];
  for (let b of a) {
    array.push(b.name);
  }
  return array.join(", ");
};

const SettingPersonelList = (props: { reload: boolean }) => {
  const [pers, setPers] = useState<Ppl[]>([]);
  const [n, setN] = useState(1);
  const { reload } = props;
  const [pg, setPg] = useState(1);
  const [pgSize, setPgSize] = useState(10);

  useEffect(() => {
    axios.get<{ result: number }>(Constants.URL_PERSONCOUNT).then((res) => {
      setN(res.data.result);
    });
    axios.get<Ppl[]>(Constants.URL_PERSON, { params: { pg:pgSize*pg, amt:pgSize*(pg-1) } }).then((res) => {
      setPers(res.data);
    });
  }, [reload]);

  const pgcount = Math.floor(n/pgSize) + (n%pgSize>0?1:0);
  
  const onChangeHandler = (page: number)=>{
    setPg(page);
    console.log(page);
    axios.get<Ppl[]>(Constants.URL_PERSON, { params: { pg:pgSize, amt:pgSize*(page-1) } }).then((res) => {
      setPers(res.data);
    });

  }

  return (
    <>
      <h3>List of Personnel</h3>
      <table className="alluser">
        <thead>
          <tr>
            <th>id</th>
            <th>Appt</th>
            <th>Rank</th>
            <th>Name</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {pers.map((p) => {
            return (
              <tr key={p.id}>
                <td>{p.id}</td>
                <td>{p.appointment.apptName}</td>
                <td>{p.rank.rankName}</td>
                <td>
                  <Link to={`/admin/person/${p.id}`}>{p.name}</Link>
                </td>
                <td>{joinArray(p.location)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <Stack spacing={2}>
        <Pagination
          count={pgcount}
          variant="outlined"
          shape="rounded"
          page={pg}
          onChange={(e, pg) =>{onChangeHandler(pg)}}
        />
      </Stack>
    </>
  );
};

export default SettingPersonelList;
