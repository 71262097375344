import axios from "axios";
import { SyntheticEvent, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import { Constants } from "../Constants";
import store from "../store/indexStore";
import { adminSlice } from "../store/admin";
import { LoginRes } from "../model";

const Login = () => {
  const navigate = useNavigate();
  const navigation = useNavigation();

  const [userName, setUserName] = useState("");
  const [pw, setPw] = useState("");
  const [loginError, setLoginError] = useState("");

  const handleSubmit = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    // if no error, mean login successful
    try {
      const response = await axios.post<LoginRes>(Constants.URL_ADMINLOGIN, {
        username: userName,
        pw,
      });
      store.dispatch(
        adminSlice.actions.setToken({
          username: userName,
          isLoggedIn: true,
          role: response.data.role,
          location: response.data.location,
          locid:response.data.locid
        })
      );
      localStorage.setItem("psau", response.data.token);
      if (response.data.token) navigate("/admin");
    } catch (error: unknown) {
      // fail login (status is 401) and become an error
      console.log(error);
      setLoginError("wrong email or/and password");
    }
  };

  return (
    <div className="logincontainer">
      <form onSubmit={handleSubmit}>
        <label htmlFor="username" style={{ display: "block" }}>
          Username
        </label>
        <input
          id="username"
          type="text"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          required
        />

        <label htmlFor="pw" style={{ display: "block" }}>
          Password
        </label>
        <input
          id="pw"
          type="password"
          value={pw}
          onChange={(e) => setPw(e.target.value)}
          required
        />

        <div className="button_submit">
          <button type="submit">
            {navigation.state === "submitting" ? "Logging in" : "login"}
          </button>
        </div>
      </form>
      {loginError !== "" && loginError}
      <br />
    </div>
  );
};

export default Login;
