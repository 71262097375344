import axios from "axios";
import { Constants } from "../Constants";
import { holidaySlice } from "../store/holiday";
import store, { RootState } from "../store/indexStore";
import { S_Event } from "../model";
import { useSelector } from "react-redux";
import errorHandler from "../util/catchError";

const SettingHolidayList = () => {
  const events: S_Event[] = useSelector(
    (state:RootState) => state.holiday
  );

  const delHandler = async (id: number) => {
    if (window.confirm("are you sure you want to delete?")) {
      try {
        await axios.delete(Constants.URL_HOLIDAY, { data: { id } });
        const res = await axios.get(Constants.URL_HOLIDAY_PUB);
        store.dispatch(holidaySlice.actions.setHoliday(res.data));
        window.alert("deleted");
      } catch (error:unknown) {
        errorHandler(error);
      }
    }
  };

  return (
    <table className="alluser">
      <thead>
        <tr>
          <th>Name of Holiday</th>
          <th>Date</th>
          <th>Delete?</th>
        </tr>
      </thead>
      <tbody>
        {events.map((e) => {
          return (
            <tr key={e.id}>
              <td>{e.name}</td>
              <td>{e.date}</td>
              <td>
                <button onClick={() => delHandler(e.id)}>delete</button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SettingHolidayList;
