import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Constants } from "../Constants";
import "./AttList.css";
import { GetAttByPidSidYr, TokenAuth } from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";

// interface GetAttByPidSidYr {
//   id: number;
//   date: string;
//   status: { id: number; state: string };
//   personnel: { id: number; name: string };
//   location: { id: number; name: string };
// }

const Attlist = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [attModel, setAttModel] = useState<GetAttByPidSidYr[]>([]);
  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );

  const pm = searchParams.get("pm") ?? 0;
  useEffect(() => {
    axios
      .get<GetAttByPidSidYr[]>(Constants.URL_ATT_PERS, {
        params: {
          pid: searchParams.get("pid"),
          statusid: searchParams.get("statusid"),
          year: searchParams.get("year"),
          pm: searchParams.get("pm"),
        },
      })
      .then((res) => setAttModel(res.data))
      .catch((err) => console.log(err));
  }, [id, searchParams]);

  return (
    <div>
      {loggedStatus.role === Constants.ADMIN_SU ? (
        <h3>
          <Link to={"/admin/person/" + id}>
            {" "}
            {id}. {attModel[0]?.personnel.name || ""}
          </Link>
        </h3>
      ) : (
        <h3>
          {id}. {attModel[0]?.personnel.name || ""}
        </h3>
      )}

      <table className="attTable">
        <thead>
          <tr>
            <th>id</th>
            <th>date</th>
            <th>am state</th>
            <th>pm state</th>
            <th>location</th>
            <th>Remark</th>
            <th>Submitted By</th>
          </tr>
        </thead>
        <tbody>
          {attModel.map((a) => {
            return (
              <tr key={a.id}>
                <td>{a.id}</td>
                <td>{a.date}</td>
                <td data-statusid={a.amstatus.id}>{a.amstatus.state}</td>
                <td data-statusid={a.pmstatus.id}>{a.pmstatus.state}</td>
                <td data-locid={a.location.id}>{a.location.name}</td>
                <td>{a.remark}</td>
                <td>{a.submittedby}</td>
                <td>
                  <Link to={"/admin/person/" + id + "/att/" + a.id}>Edit</Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Attlist;
