import React, { SyntheticEvent, useEffect } from "react";
import { useState } from "react";
import {
  Person,
  PplinMCResult,
  TokenAuth,
  Status,
  selectOpt,
  Attendance,
  AttendanceGetbyLocDate,
  S_Event,
} from "../model";
import { useSelector } from "react-redux";
import { RootState } from "../store/indexStore";
import axios, { AxiosError } from "axios";
import { Constants } from "../Constants";
import "./ParadeState.css";
import Select, { MultiValue, SingleValue } from "react-select";
import { Link } from "react-router-dom";
import ParadeStateTable from "./ParadeStateTable";
import ParadeState_calender from "./ParadeState_calender";
import StrengthTable from "./StrengthTable";
import ParadeStateCopyTable from "./ParadeStateCopyTable";
import ParadeStateSubmitted from "./ParadeStateSubmited";

const ParadeState = () => {
  const d = new Date();
  const localDateStr = d.toLocaleDateString().split("/");
  const today = new Date(d.toDateString());
  const [persons, setPersons] = useState<Person[]>([]);
  const [attendances, setAttendances] = useState<Attendance[]>([]);
  const [submitBy, setSubmitBy] = useState("");
  const [refresh, setRefresh] = useState(false);
  const loggedStatus: TokenAuth = useSelector(
    (state: RootState) => state.admin
  );
  const states: Status[] = useSelector((state: RootState) => state.status);
  const statuss: selectOpt[] = [];
  for (let s of states) statuss.push({ value: +s.id, label: s.state });
  const [events, setEvents] = useState<S_Event[]>([]);

  useEffect(() => {
    const persons: Person[] = [];
    const attendances: Attendance[] = [];
    axios
      .get<PplinMCResult>(
        Constants.URL_PERSON_IN_MC + "?mc=" + loggedStatus.location
      )
      .then((res) => {
        res.data.personnel.forEach((p) => {
          persons.push({
            id: p.id,
            name: p.name,
            rankName: p.rank.rankName,
            apptName: p.appointment.apptName,
          });
          attendances.push({
            // date: d.toISOString().split("T")[0],
            date: `${localDateStr[2]}-${localDateStr[1]}-${localDateStr[0]}`,
            personnel: p.id,
            amstatus: Constants.STATUS_PRESENT,
            pmstatus: Constants.STATUS_PRESENT,
            remark: undefined,
            // location: +(store.getState().admin.location as string)
            locid: loggedStatus.locid!,
            submittedby: "",
          });
          // setAttendances(attendances);
        });
        persons.sort((m1,m2) => (Constants.apptNameRankingMap.get(m1.apptName)??0) -
        (Constants.apptNameRankingMap.get(m2.apptName)??0))
        setPersons(persons);
      })
      .then(() => {
        return axios
          .get<AttendanceGetbyLocDate[]>(Constants.URL_ATTENDANCE, {
            params: {
              locid: loggedStatus.locid,
              date: d.toISOString().split("T")[0],
            },
          })
          .then((res) => {
            for (let a of res.data) {
              let att = attendances.filter(
                (x) => x.date === a.date && x.personnel == a.personnel.id
              )[0];
              att.attid = a.id;
              att.amstatus = a.amstatus.id;
              att.pmstatus = a.pmstatus.id;
              att.remark = a.remark;
              att.submittedby = a.submittedby;
            }
            setAttendances(attendances);
            console.log(attendances);
          });
      })
      .catch((err) => console.log(err));
    axios
      .get(Constants.URL_EVENT_ALL)
      .then((res) => {
        let evts: S_Event[] = res.data;
        evts.reverse();
        setEvents(evts);
      })
      .catch((err) => console.log(err));
  }, [loggedStatus.location, refresh]);

  const amstatusSelectHandler = (e: SingleValue<selectOpt>, id: number) => {
    if (e?.value) {
      attendances.filter((a) => a.personnel === id)[0].amstatus = +e?.value;
      setAttendances(attendances);
    }
  };

  const pmstatusSelectHandler = (e: SingleValue<selectOpt>, id: number) => {
    if (e?.value) {
      attendances.filter((a) => a.personnel === id)[0].pmstatus = +e?.value;
      setAttendances(attendances);
    }
  };

  const remarkChangeHandler = (str: string, id: number) => {
    attendances.filter((a) => a.personnel === id)[0].remark = str;
    setAttendances(attendances);
  };

  const formChecker = (a: Attendance) => {
    const rmkNeeded = [9, 10, 11, 12, 13, 14, 15, 16];
    if (
      rmkNeeded.includes(a.amstatus || 0) &&
      (!a.remark || a.remark.length <= 0)
    )
      return false;
    return true;
  };

  const submitHandler = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    e.preventDefault();
    try {
      let i = 0;
      for (let a of attendances) {
        if (!formChecker(a)) throw new Error(`row ${i + 1} is missing remark`);
        a.submittedby = submitBy;
        i++;
      }
      if (window.confirm("do you want to submit parade state?")) {
        const result = await axios.post(Constants.URL_ATTENDANCE, attendances);
        setRefresh((prev) => !prev);
        setAttendances(attendances);
        window.alert("submitted");
      }
    } catch (error:unknown) {
      if (error instanceof AxiosError) window.alert(error.response?.data)
      else if (error instanceof Error) window.alert(error.message)
      else window.alert(error);
    }
  };
  return (
    <div className="PsContainer">
      <ParadeState_calender events={events} />
      <br />
      Date : {d.toDateString()}
      {attendances.filter((x) => x.attid).length < attendances.length && (
        <div>
          <h3>Unsubmitted Parade State</h3>
          <form onSubmit={submitHandler}>
            <table className="PsTable">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Appt</th>
                  <th>Rank</th>
                  <th>Name</th>
                  <th>am Status</th>
                  <th>pm Status</th>
                  <th>Remark</th>
                  <th>Leave</th>
                </tr>
              </thead>
              <tbody>
                {persons.map((p) => {
                  if (
                    attendances.filter((x) => x.personnel === p.id && !x.attid)
                      .length === 1
                  )
                    return (
                      <tr key={p.id}>
                        <td>{p.id}</td>
                        <td>{p.apptName}</td>
                        <td>{p.rankName}</td>
                        <td>{p.name}</td>
                        <td>
                          <Select
                            options={statuss}
                            defaultValue={
                              statuss.filter((x) => x.label === "Present")[0]
                            }
                            onChange={(e) => {
                              amstatusSelectHandler(e, p.id);
                            }}
                          />
                        </td>
                        <td>
                          <Select
                            options={statuss}
                            defaultValue={
                              statuss.filter((x) => x.label === "Present")[0]
                            }
                            onChange={(e) => {
                              pmstatusSelectHandler(e, p.id);
                            }}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            onChange={(e) =>
                              remarkChangeHandler(e.target.value, p.id)
                            }
                          />
                        </td>
                        <td>
                          <Link to={"/admin/leave/" + p.id}>Leave</Link>
                        </td>
                      </tr>
                    );
                  else return;
                })}
              </tbody>
            </table>
            <input
              type="text"
              placeholder="submitted by??"
              value={submitBy}
              onChange={(e) => setSubmitBy(e.target.value)}
            />
            <button type="submit" disabled={submitBy.trim().length < 3}>
              Submit
            </button>
          </form>
          <br />
          <ParadeStateTable />
        </div>
      )}
      <ParadeStateSubmitted attendances={attendances} persons = {persons}/>
      <br />
      <ParadeStateCopyTable attendances={attendances} d={d} persons={persons} />
      <br />
      <StrengthTable attendances={attendances} subtitle="strength"/>
    </div>
  );
};

export default ParadeState;
